import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';

const routesIssueList = [
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_ISSUES,
    path: 'issues',
    component: () => import('@/app/_employees/subpages/issue_lists/IssueListUnitSubpage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_TICKETS,
    path: 'tickets',
    props: true,
    component: () => import('@/app/_employees/subpages/issue_lists/IssueListTicketsSubpage.vue'),
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_UNIT_SHOW_TICKET,
    path: 'issue/:issue_id/ticket/:ticket_id',
    props: true,
    component: () => import('@/app/_employees/subpages/issues/IssueTicketSubpage.vue'),
  },
];

export default routesIssueList;
