<template>
  <div
    v-if="initialized"
    id="app"
    class="ziggu_lg_horizontal"
    :class="{
      'environment-staging-border': environment === VITE_ENV_STAGING,
      'environment-development-border': environment === VITE_ENV_DEVELOPMENT,
      'environment-review-border': environment === VITE_ENV_REVIEW,
    }">
    <InvoiceOverdueBanner
      v-if="billing_current_client && billing_current_client.attributes.invoice_overdue"
      :billing="billing_current_client"/>
    <OutOfOfficeBanner
      v-if="current_client.attributes.ooo"
      :client="current_client"
      :profile="current_profile"/>
    <NavbarDesktopClientHorizontal/>
    <NavbarDesktopClientHorizontalOverview v-if="is_overview_route"/>
    <NavbarDesktopClientHorizontalSecondary v-else/>
    <div
      v-if="current_project && current_project.attributes.skip_customer"
      id="information_banner">
      <div
        class="has-background-primary-light has-text-color-primary py-4
      has-text-weight-medium is-flex is-justify-content-center is-align-items-center">
        <span v-if="$flipper.enabled($FT.TEMPLATE_PROJECTS)">
          <FontAwesomeIcon
            class="mr-2"
            :icon="['far', 'memo']"
          />
          {{ $t('project.labels.youAreEditingTemplateProject') }}
        </span>
        <span v-else>
          <ButtonUpsell feature="project_templates">
            {{ $t('upsell.project_templates.cta2') }}
          </ButtonUpsell>
        </span>
      </div>

    </div>
    <div v-else-if="current_project && !current_project.attributes.activated"
         id="information_banner">
      <div
        class="has-background-primary-light has-text-color-primary py-4
          has-text-weight-medium is-flex is-justify-content-center is-align-items-center">
        <FontAwesomeIcon
          class="mr-2"
          icon="toggle-off"
        />
        {{ $t('project.labels.projectIsDeactivated') }}
      </div>
    </div>
    <main id="page" :class="{'sidebar-padding': mq.isDesktopWide && sidebar_type }">
      <div class="container is-widescreen h-full" :class="{ 'section': mq.isDesktop }">
        <router-view/>
      </div>
    </main>

  </div>
  <b-loading v-else :is-full-page="true" :model-value="!initialized"/>
</template>

<script>
import NavbarDesktopClientHorizontalOverview
  from '@/app/_employees/components/navbar/TheNavbarDesktopClientHorizontalOverview.vue';
import NavbarDesktopClient from '@/app/_employees/components/navbar/TheNavbarDesktopClient.vue';
import NavbarDesktopClientHorizontal from '@/app/_employees/components/navbar/TheNavbarDesktopClientHorizontal.vue';
import NavbarDesktopClientHorizontalSecondary from '@/app/_employees/components/navbar/TheNavbarDesktopClientHorizontalSecondary.vue';
import NavbarDesktopProject from '@/app/_employees/components/navbar/TheNavbarDesktopProject.vue';
import NavbarTablet from '@/app/_employees/components/navbar/TheNavbarTablet.vue';
import TopbarDesktop from '@/app/shared_components/navbar/TheTopbarDesktop.vue';
import TopbarTablet from '@/app/shared_components/navbar/TheTopbarTablet.vue';
import OutOfOfficeBanner from '@/app/shared_components/banner/TheOutOfOfficeBanner.vue';
import InvoiceOverdueBanner from '@/app/_employees/components/InvoiceOverdueBanner.vue';
import profile_mixin from '@/app/util/profile_mixin';
import { useMqService } from '@/plugins/mq';
import { mapGetters } from 'vuex';
import { ROUTES_EMPLOYEE_OVERVIEWS } from '@/app/data/route_constants';

export default {
  name: 'Employee',
  components: {
    NavbarDesktopClientHorizontalOverview,
    NavbarDesktopClientHorizontal,
    NavbarDesktopClientHorizontalSecondary,
    InvoiceOverdueBanner,
    NavbarDesktopClient,
    NavbarDesktopProject,
    NavbarTablet,
    OutOfOfficeBanner,
    TopbarDesktop,
    TopbarTablet,
  },
  mixins: [profile_mixin],
  setup() {
    const mq = useMqService();
    return { mq };
  },

  computed: {
    ...mapGetters(['current_client', 'current_project']),
    is_overview_route() {
      return Object.values(ROUTES_EMPLOYEE_OVERVIEWS).includes(this.$route.name);
    }
  },

  async created() {
    await this.initProfile();
  },
};
</script>
