<template>
  <div v-if="initialized" id="app"
       :class="{
         'environment-staging-border': environment === VITE_ENV_STAGING,
         'environment-development-border': environment === VITE_ENV_DEVELOPMENT,
         'environment-review-border': environment === VITE_ENV_REVIEW,
         'impersonate-border': current_profile_true.type === PROFILE_TYPES.EMPLOYEE
       }">
    <div v-if="current_profile_true.type === PROFILE_TYPES.EMPLOYEE"
         class="impersonate-button"
         @click="emitter.$emit('stopImpersonate')">
      <div class="button is-white is-outlined">
        {{ $t('contact.actions.impersonateStop') }} {{ current_profile.attributes.name }}
      </div>
    </div>
    <div
      :class="{ 'ziggu_sm': mq.isMobile || mq.isTablet, 'ziggu_lg': mq.isDesktop }">
      <NavbarTablet v-if="(mq.isMobile || mq.isTablet)"/>
      <NavbarDesktopClient v-if="mq.isDesktop && !project_id"/>
      <NavbarDesktopProject v-if="mq.isDesktop && project_id"/>
      <TopbarDesktop v-if="mq.isDesktop"/>
      <TopbarTablet v-else/>
      <OutOfOfficeBanner
        v-if="current_client.attributes.ooo"
        :client="current_client"
        :profile="current_profile"/>
      <main id="page" :class="{'sidebar-padding': mq.isDesktopWide && sidebar_type }">
        <div class="section container h-full">
          <router-view/>
        </div>
      </main>
    </div>
  </div>
  <div v-else>
    <b-loading :is-full-page="true" :model-value="!initialized"/>
  </div>
</template>

<script>
import NavbarDesktopClient from '@/app/_partners/components/navbar/TheNavbarDesktopClient.vue';
import NavbarDesktopProject from '@/app/_partners/components/navbar/TheNavbarDesktopProject.vue';
import NavbarTablet from '@/app/_partners/components/navbar/TheNavbarTablet.vue';
import OutOfOfficeBanner from '@/app/shared_components/banner/TheOutOfOfficeBanner.vue';
import TopbarDesktop from '@/app/shared_components/navbar/TheTopbarDesktop.vue';
import TopbarTablet from '@/app/shared_components/navbar/TheTopbarTablet.vue';
import profile_mixin from '@/app/util/profile_mixin';
import { useMqService } from '@/plugins/mq';

export default {
  name: 'Partner',
  components: {
    NavbarDesktopClient,
    NavbarDesktopProject,
    NavbarTablet,
    OutOfOfficeBanner,
    TopbarDesktop,
    TopbarTablet,
  },
  mixins: [profile_mixin],

  setup() {
    const mq = useMqService();
    return { mq };
  },

  async created() {
    await this.initProfile();
  },
};
</script>
