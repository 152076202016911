<template>
  <nav aria-label="breadcrumbs" class="breadcrumb">
    <ul>
      <template v-for="crumb in $breadcrumbs.crumbs.value" :key="crumb">
        <li :class="{ 'is-active': crumb.active }">
          <router-link v-if="crumb.path"
                       :key="crumb.name"
                       aria-current="page"
                       :data-test="crumb.dataTest"
                       :to="{ name: crumb.path, params: crumb.params, query: crumb.query }">
            <FontAwesomeIcon
              v-if="crumb.icon"
              :icon="crumb.icon"
              style="margin-right: 0.5rem; margin-top: 0.1rem;"/>
            <div>
              {{ $truncate(crumb.name, 30) }}
            </div>
          </router-link>
          <router-link v-else to="#">
            {{ $truncate(crumb.name, 50) }}
          </router-link>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>

export default {
  name: 'Breadcrumbs'
};
</script>
