import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';

const routesSales = [
  {
    name: ROUTES_EMPLOYEES.PROJECT_SALES_UNITS,
    path: 'units',
    component: () => import('@/app/_employees/subpages/sales/SalesUnitsPage.vue'),
    props: true,
  }
];

export default routesSales;
