import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import routesDecision from '@/router/employee/project/routes-decision';
import routesDecisionType from '@/router/employee/project/routes-decision-type';
import routesSurveyType from '@/router/employee/project/routes-survey-type';
import routesSales from '@/router/employee/project/routes-sales';
import routesInformation from '@/router/employee/project/routes-information';
import routesIssueListType from '@/router/employee/project/routes-issue-list-type';
import routesIssueList from '@/router/employee/project/routes-issue-list';
import DecisionTypeRedirect from '@/app/_employees/pages/DecisionTypeRedirect.vue';

const routesProject = [
  {
    path: 'milestones',
    component: () => import('@/app/_employees/components/levels/project/TheProjectMilestones.vue'),
    props: true,
    children: [
      {
        name: ROUTES_EMPLOYEES.PROJECT_MILESTONES,
        path: '',
        component: () => import('@/app/_employees/components/milestones/MilestonesList.vue'),
        props: true,
      },
      {
        name: ROUTES_EMPLOYEES.PROJECT_MILESTONES_UNITS,
        path: 'units',
        component: () => import('@/app/_employees/components/milestones/MilestonesUnits.vue'),
        props: true,
      }
    ]
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_ANNOUNCEMENTS,
    path: 'announcements',
    component: () => import('@/app/_employees/pages/AnnouncementsPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_SALES,
    path: 'sales',
    component: () => import('@/app/_employees/pages/SalesPage.vue'),
    props: true,
    children: routesSales,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_TYPES,
    path: 'decision_types',
    component: () => import('@/app/_employees/pages/DecisionTypesPage.vue'),
    props: true,
  },
  {
    path: 'decision_types/:decision_type_id',
    component: () => import('@/app/_employees/pages/DecisionTypePage.vue'),
    children: routesDecisionType,
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_TYPE_REDIRECT,
    path: 'decision_types/:decision_type_id/unit/:unit_id',
    component: DecisionTypeRedirect,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_NEW,
    path: 'decision_types/:decision_type_id/decisions/new',
    component: () => import('@/app/_employees/pages/DecisionsNewPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_EDIT,
    path: 'decision_types/:decision_type_id/decisions/edit',
    component: () => import('@/app/_employees/pages/DecisionsNewPage.vue'),
    props: true,
  },
  {
    path: 'decision_types/:decision_type_id/',
    redirect: { name: ROUTES_EMPLOYEES.PROJECT_DECISIONS },
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_TYPE_DUPLICATE,
    path: 'decisions/duplicate',
    component: () => import('@/app/_employees/pages/DecisionsNewPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_TYPE_NEW,
    path: 'decisions/new',
    component: () => import('@/app/_employees/pages/DecisionsNewPage.vue'),
    props: true,
  },
  {
    path: 'decision/:decision_id/',
    component: () => import('@/app/_employees/pages/DecisionPage.vue'),
    children: routesDecision,
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INSTALLMENT_GROUP,
    path: 'installment_groups/:installment_group_name/installments',
    component: () => import('@/app/_employees/pages/InstallmentGroupPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INSTALLMENT_GROUPS,
    path: 'installment_groups',
    component: () => import('@/app/_employees/pages/InstallmentGroupsPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INSTALLMENT_GROUPS_MATRIX,
    path: 'installments_matrix',
    component: () => import('@/app/_employees/pages/InstallmentGroupsMatrixPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INSTALLMENT_GROUP_NEW,
    path: 'installment_groups/new',
    component: () => import('@/app/_employees/pages/InstallmentGroupNewPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INSTALLMENT_NEW,
    path: 'installment_groups/:installment_group_name/installments/new',
    component: () => import('@/app/_employees/pages/InstallmentGroupNewPage.vue'),
    props: true,
  },
  {
    // SUP only
    name: ROUTES_EMPLOYEES.PROJECT_INSTALLMENTS,
    path: 'installments',
    component: () => import('@/app/_employees/pages/InstallmentGroupPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_LIST_TYPES,
    path: 'issue_list_types',
    component: () => import('@/app/_employees/pages/IssueListTypesPage.vue'),
    props: true,
  },
  // When creating a new unit_list_type
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_LIST_TYPE_NEW,
    path: 'issue_list_types/new',
    component: () => import('@/app/_employees/pages/IssueListTypeNewPage.vue'),
    props: true,
  },
  // When adding extra units to an existing unit_list_type
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_LISTS_NEW,
    path: 'issue_list_types/:issue_list_type_id/new',
    component: () => import('@/app/_employees/pages/IssueListTypeNewPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_INBOX,
    path: 'issues/inbox',
    component: () => import('@/app/_employees/pages/IssueInbox.vue'),
    props: true,
  },
  {
    path: 'issue_list_types/:issue_list_type_id/',
    component: () => import('@/app/_employees/pages/IssueListTypePage.vue'),
    children: routesIssueListType,
    props: true,
  },
  {
    path: 'issue_list_types/:issue_list_type_id/issue_list/:issue_list_id',
    component: () => import('@/app/_employees/pages/IssueListPage.vue'),
    children: routesIssueList,
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_SURVEY_TYPES,
    path: 'survey_types',
    component: () => import('@/app/_employees/pages/SurveyTypesPage.vue'),
    props: true,
  },
  {
    path: 'survey_types/:survey_type_id',
    component: () => import('@/app/_employees/pages/SurveyTypePage.vue'),
    children: routesSurveyType,
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_SURVEY_NEW,
    path: 'survey_types/:survey_type_id/surveys/new',
    component: () => import('@/app/_employees/pages/SurveysNewPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_SURVEY_TYPE_DUPLICATE,
    path: 'surveys/duplicate',
    component: () => import('@/app/_employees/pages/SurveysNewPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_SURVEY_TYPE_NEW,
    path: 'surveys/new',
    component: () => import('@/app/_employees/pages/SurveysNewPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_DOCUMENTS,
    path: 'documents',
    component: () => import('@/app/_employees/pages/DocumentsPage.vue'),
    props: true,
  },
  {
    path: 'information/levels/',
    component: () => import('@/app/_employees/pages/LevelsPage.vue'),
    children: routesInformation,
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_SPACES,
    path: 'information/spaces',
    component: () => import('@/app/_employees/pages/SpacesPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_QUESTION_LISTS,
    path: 'question_lists',
    component: () => import('@/app/_employees/pages/QuestionListsPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_QUESTION_LISTS_SHOW,
    path: 'question_list/:question_list_id',
    component: () => import('@/app/_employees/pages/QuestionListPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_TODO_LISTS,
    path: 'todo_lists',
    component: () => import('@/app/_employees/pages/TodoListsPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_EMAIL_CONVERSATIONS,
    path: 'inbound_emails',
    component: () => import('@/app/_employees/pages/EmailConversationsPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_EMAIL_CONVERSATIONS_SHOW,
    path: 'inbound_emails/:email_conversation_id',
    component: () => import('@/app/_employees/pages/EmailConversationPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_TICKETS,
    path: 'tickets',
    component: () => import('@/app/_employees/pages/TicketsPage.vue'),
    props: true,
  },
  {
    name: ROUTES_EMPLOYEES.PROJECT_TICKETS_SHOW,
    path: 'ticket/:ticket_id',
    component: () => import('@/app/_employees/pages/TicketPage.vue'),
    props: true,
  },
  {
    path: '',
    name: ROUTES_EMPLOYEES.PROJECT,
    redirect: { name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION },
  },
];

export default routesProject;
