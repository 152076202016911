import { ROUTES_PARTNERS } from '@/app/data/route_constants';

const routesIssueList = [
  {
    name: ROUTES_PARTNERS.PROJECT_ISSUE_UNIT_ISSUES,
    path: 'issues',
    component: () => import('@/app/_partners/subpages/issue_lists/IssueListUnitSubpage.vue'),
    props: true,
  }
];

export default routesIssueList;