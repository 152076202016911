<template>
  <div>
    <b-autocomplete
      ref="autocomplete"
      v-model="unit_name"
      clearable
      :data="filteredUnits"
      data-test="select-unit-input"
      :disabled="isDisabled"
      field="attributes.name"
      iconRight="circle-xmark"
      open-on-focus
      @focus="fetchUnits"
      @select="selectUnit"
      @update:modelValue="searchUnit">
      <template #default="props">
        {{ props.option.attributes.name_with_customer }}
      </template>
      <template #empty>
        <div data-test="empty-result">
          <div v-if="is_searching">
            {{ $t('select.actions.noResults') }} <span v-if="unit_name">"{{ unit_name }}"</span>
          </div>
          <div v-else>
            {{ $t('select.actions.startTyping') }}
          </div>
        </div>
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash-es';

export default {
  name: 'SelectUnit',
  props: {
    active: { type: Object, default: null },
    project_id: { type: String, default: null },
    project_required: { type: Boolean },
    units_prop: { type: Object, default: null },
  },

  emits: ['change-select'],

  data() {
    return {
      unit_name: this.active ? this.active.attributes.name : '',
      is_searching: false,
      units_autocomplete: {},
    };
  },

  computed: {
    ...mapGetters([
      'units',
    ]),

    isDisabled() {
      return this.project_required && _.isNil(this.project_id);
    },

    filteredUnits() {
      const units = this.units_prop || this.units;
      return _.filter(units,
        (p) => p.attributes.name_with_customer.toLowerCase().indexOf(this.unit_name.toLowerCase()) >= 0);
    },
  },

  watch: {
    active(newVal) {
      this.unit_name = _.get(newVal, 'attributes.name', '');
    },
  },
  methods: {
    ...mapActions([
      'AUTOCOMPLETE_UNITS',
    ]),

    fetchUnits: _.debounce(async function fetchUnits(search = '') {
      this.units_autocomplete = await this.AUTOCOMPLETE_UNITS(_.pickBy({
        name_or_customer_group_name_cont: search,
        project_id: this.project_id,
        customer_group_id_not_null: true,
      }));
    }, 250),

    searchUnit(query) {
      if (query.length >= 1) {
        this.is_searching = true;
        if (!this.active) {
          this.fetchUnits(query);
        }
      }
    },

    selectUnit(unit) {
      this.$emit('changeSelect', unit);
    },
  },
};
</script>
